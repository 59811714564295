import * as React from 'react';
import { Button } from '../../../../components/workspace-layout/components';

type RemoveButtonProps = {
  onClick: () => void;
};

export const RemoveButton = ({ onClick }: RemoveButtonProps) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: 'grey.900',
        fontSize: '14px',
        lineHeight: '14px',
        height: '24px',
        width: '24px',
        borderRadius: '100%',
        padding: 0,
      }}
    >
      X
    </Button>
  );
};
