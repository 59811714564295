import { useCreateItem } from './create-item';
import { useCreateWarehouse } from './create-warehouse';
import { useGetAllItems } from './get-all-items';
import { useGetAllWarehouses } from './get-all-warehouses';
import { useUploadItemImage } from './upload-item-image';
import { useGetClientProjects } from './get-client-projects';
import { useUploadWarehouseImage } from './upload-warehouse-image';
import { useGetWorkspaceClients } from './get-workspace-clients';
import { useCreateDelivery } from './create-delivery';
import { useGetAllDeliveries } from './get-all-deliveries';
import { useGetDelivery } from './get-delivery';
import { useDeleteDelivery } from './delete-delivery/hook';
import { useUpdateDelivery } from './update-delivery';

export const WarehouseService = {
  useCreateWarehouse,
  useGetAllWarehouses,
  useUploadWarehouseImage,
  useCreateItem,
  useGetAllItems,
  useUploadItemImage,
  useGetClientProjects,
  useGetWorkspaceClients,
  useCreateDelivery,
  useGetAllDeliveries,
  useGetDelivery,
  useDeleteDelivery,
  useUpdateDelivery,
};
