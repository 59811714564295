import { FieldFileUpload } from 'components/form-elements/Forms/FieldFileUpload';
import { Provider, useFormProvider } from './Provider';
import { SubmitButton } from './SubmitButton';
import { FieldEditText } from './FieldEditText';
import { SelectField } from './SelectField';
import { CheckboxField } from './Checkbox';

export const Forms = {
  Provider,
  FieldEditText,
  SelectField,
  SubmitButton,
  FieldFileUpload,
  CheckboxField,

  useFormProvider,
};
