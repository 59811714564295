import { Flex } from 'components/layout';
import { Link } from 'components/links';
import * as Icons from 'components/icons';
import { GetWarehouseResponse } from 'trace-backend-sdk';
import { Text } from 'components/typography';
import { Image } from 'components/Image';
import { UsersService } from 'services/users';
import { LOCAL_STORAGE_KEYS, useLocalStorage } from 'utils/local-storage';
import placeholderImage from '../../../../features/warehouses/assets/placeholderWarehouseImage.jpg';

interface WarehouseProps {
  warehouse: GetWarehouseResponse;
}

export function WarehouseCard({ warehouse }: WarehouseProps) {
  const [, setSelectedWarehouse] = useLocalStorage<GetWarehouseResponse>(
    LOCAL_STORAGE_KEYS.SELECTED_WAREHOUSE,
  );

  const { image, shouldShowFallback } = UsersService.useFetchAvatarImage(
    warehouse?.imageUrl,
  );

  const handleLinkOnClick = (warehouse: GetWarehouseResponse) => {
    setSelectedWarehouse(warehouse);
  };

  return (
    <Link
      to={`warehouse/${warehouse.id}`}
      sx={{
        textDecoration: 'none',
        padding: 0,
        margin: 0,
        maxWidth: '600px',
        my: 5,
      }}
      onClick={() => handleLinkOnClick(warehouse)}
      key={warehouse.id}
    >
      <Flex
        sx={{
          maxWidth: '600px',
          backgroundColor: '#FFF',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          py: 5,
          px: 4,
          borderRadius: 12,
          textDecoration: 'none',
          color: 'textprimary.900',
        }}
      >
        <Text sx={{ fontWeight: 500, fontSize: '2xl' }}>{warehouse.name}</Text>
        <Image
          src={shouldShowFallback ? placeholderImage : image}
          alt="warehouse"
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '250px',
            borderRadius: '8px',
          }}
        />
        <Flex
          sx={{
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Icons.City />
          <Text
            sx={{
              fontWeight: 500,
              fontSize: 'md',
              color: 'grey.900',
            }}
          >
            City:
          </Text>
          <Text sx={{ fontWeight: 500 }}>{warehouse.city}</Text>
        </Flex>
        <Flex
          sx={{
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Icons.Address />
          <Text
            sx={{
              fontWeight: 500,
              fontSize: 'md',
              color: 'grey.900',
            }}
          >
            Address:
          </Text>
          <Text sx={{ fontWeight: 500 }}>{warehouse.address}</Text>
        </Flex>
      </Flex>
    </Link>
  );
}
