import * as React from 'react';
import { UsersService } from 'services/users';
import { Flex } from '../../../../components/layout';
import { Image } from '../../../../components/Image';
import { Text } from '../../../../components/typography';
import { Button } from '../../../../components/workspace-layout/components';
import * as Icons from '../../../../components/icons';
import { RemoveButton } from '../RemoveButton/RemoveButton';
import placeholderImage from '../../assets/placeholderWarehouseImage.jpg';

type CreateEventItemCardProps = {
  name: string;
  project?: string;
  stock?: number;
  imageUrl?: string;
  category?: string;
  itemQuantity: number;
  handleMinusButtonClick: () => void;
  handleItemQuantityChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePlusButtonClick: () => void;
  handleRemoveItem: () => void;
};

export const CreateEventItemCard = ({
  name,
  project,
  category,
  imageUrl,
  stock,
  itemQuantity,
  handleMinusButtonClick,
  handleItemQuantityChange,
  handlePlusButtonClick,
  handleRemoveItem,
}: CreateEventItemCardProps) => {
  const { image, shouldShowFallback } = UsersService.useFetchAvatarImage(imageUrl);

  return (
    <Flex
      sx={{
        backgroundColor: 'white.50',
        p: '10px',
        my: '16px',
        minHeight: '100px',
        borderRadius: 2,
      }}
    >
      <Flex
        sx={{
          backgroundColor: 'transparent',
          width: '100px',
          height: '80px',
          mr: '20px',
          flexShrink: 0,
        }}
        alignItems="flex-start"
      >
        <Image
          src={shouldShowFallback ? placeholderImage : image}
          alt={name}
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            borderRadius: 1,
          }}
        />
      </Flex>
      <Flex
        sx={{ mr: '20px' }}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Text
          sx={{
            fontSize: 20,
            fontWeight: 500,
            lineHeight: '20px',
          }}
        >
          {name}
        </Text>
        <Text variant="userCardTitle" sx={{ fontWeight: 500, fontSize: 'md' }}>
          {project}
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        sx={{ width: 'fit-content', flexShrink: 0, pr: '0px' }}
      >
        <Flex
          sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
        >
          <Text variant="caption" sx={{ fontSize: '16px', lineHeight: '16px' }}>
            In Stock: <Text sx={{ fontWeight: 600 }}>{stock}</Text>
          </Text>

          <RemoveButton onClick={handleRemoveItem} />
        </Flex>
        <Flex sx={{ gap: '0px', justifyContent: 'center', mr: '60px' }}>
          <Flex sx={{ gap: '10px' }}>
            <Button
              onClick={handleMinusButtonClick}
              prependIcon={<Icons.Remove />}
              variant="secondary"
              sx={{
                height: '30px',
                width: '48px',
                flexShrink: 0,
              }}
            />
            <input
              name="quantity"
              type="number"
              value={itemQuantity}
              onChange={handleItemQuantityChange}
              style={{ width: '120px' }}
            />
            <Button
              onClick={handlePlusButtonClick}
              prependIcon={<Icons.Add />}
              variant="primary"
              sx={{
                height: '30px',
                width: '48px',
                flexShrink: 0,
              }}
            />
          </Flex>
        </Flex>
        <Flex sx={{ justifyContent: 'flex-end' }}>
          <Text variant="cardLabelSmall" sx={{ lineHeight: '16px' }}>
            {category}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
