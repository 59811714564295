import * as React from 'react';
import { LocaleKey } from 'translations';
import { Flex } from '../../../../../../components/layout';
import { Text } from '../../../../../../components/typography';
import { Button } from '../../../../../../components/form-elements';
import { Image } from '../../../../../../components/Image';
import {
  GlobalWarehouseItem,
  WarehouseRole,
} from '../../../../../../features/warehouses/types/warehousesTypes';
import { useGlobalState } from '../../../../../../components/seo/GlobalState';
import { ShouldRender } from '../../../../../../components/ShouldRender';

type WarehouseItemCardProps = {
  item: GlobalWarehouseItem;
  selectedDeliveryItems: GlobalWarehouseItem[] | undefined;
  handleSelectedDeliveryItemsChange: (item: GlobalWarehouseItem[]) => void;
};

type WarehouseItemCardListRowProps = {
  title: LocaleKey;
  value?: string | number;
};

export const WarehouseItemCard = ({
  item,
  selectedDeliveryItems,
  handleSelectedDeliveryItemsChange,
}: WarehouseItemCardProps) => {
  const { image } = item;
  const { name } = item;
  const { description } = item;
  const { project } = item;
  const { stock } = item;
  const { category } = item;
  const { company } = item;
  const { color } = item;
  const { weight } = item;
  const { size } = item;
  const { position } = item;
  const { client } = item;
  const { zone } = item;
  const { floor } = item;

  const {
    activeOrderItemsGlobal,
    setActiveOrderItemsGlobal,
    warehouseRoleGlobal,
  } = useGlobalState();

  const checkIfActiveOrderHasItem = () =>
    activeOrderItemsGlobal.some(
      (activeOrderItem) => activeOrderItem.id === item.id,
    );

  const checkIfActiveDeliveryHasItem = () =>
    selectedDeliveryItems?.some(
      (selectedOrderItem) => selectedOrderItem.id === item.id,
    );

  const handleAddItemToActiveOrder = () => {
    setActiveOrderItemsGlobal((prevState: GlobalWarehouseItem[]) => {
      const updatedItems: GlobalWarehouseItem[] = [...prevState];
      updatedItems.push(item);
      return updatedItems;
    });
  };

  const handleAddItemToActiveDelivery = () => {
    if (selectedDeliveryItems) {
      const updatedActiveDeliveryGlobal = [...selectedDeliveryItems, item];
      handleSelectedDeliveryItemsChange(updatedActiveDeliveryGlobal);
    }
  };

  const WarehouseItemCardListRow = ({
    title,
    value,
  }: WarehouseItemCardListRowProps) => {
    return (
      <li>
        <Flex>
          <Text
            sx={{
              fontSize: 'md',
            }}
            intlId={title}
          />
          <Text sx={{ fontSize: 'md', mr: 1 }}>:</Text>
          <Text variant="body2">{value}</Text>
        </Flex>
      </li>
    );
  };

  return (
    <Flex
      sx={{
        backgroundColor: 'white.50',
        p: '20px',
        my: '20px',
        minHeight: '200px',
        borderRadius: 2,
      }}
    >
      <Flex
        sx={{
          backgroundColor: 'transparent',
          width: '200px',
          mr: '20px',
          flexShrink: 0,
        }}
        alignItems="flex-start"
      >
        <Image
          src={image}
          alt={name}
          sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      </Flex>

      <Flex
        sx={{ mr: '20px' }}
        flexDirection="column"
        justifyContent="space-between"
        gap={2}
      >
        <Flex flexDirection="column" gap={1}>
          <Flex gap={1} alignContent="center">
            <Text
              sx={{
                fontSize: 20,
                fontWeight: 500,
                lineHeight: '20px',
              }}
            >
              {name}
            </Text>

            <ShouldRender
              when={warehouseRoleGlobal === WarehouseRole.Administrator}
            >
              <Text
                sx={{
                  fontSize: 'md',
                  alignSelf: 'flex-end',
                  fontStyle: 'italic',
                }}
              >
                ({position})
              </Text>
            </ShouldRender>
          </Flex>
          <Text variant="body2">{description}</Text>

          <ul style={{ paddingLeft: '20px' }}>
            <WarehouseItemCardListRow
              title="item.itemDetails.zone"
              value={zone}
            />

            <WarehouseItemCardListRow
              title="item.itemDetails.floor"
              value={floor}
            />

            <WarehouseItemCardListRow
              title="item.itemDetails.producer"
              value={company}
            />

            <WarehouseItemCardListRow
              title="createItem.form.input.color.label"
              value={color}
            />

            <WarehouseItemCardListRow
              title="createItem.form.input.weight.label"
              value={weight}
            />
            <WarehouseItemCardListRow
              title="createItem.form.input.size.label"
              value={size}
            />
          </ul>
        </Flex>

        <Text variant="userCardTitle" sx={{ fontWeight: 500, fontSize: 'md' }}>
          {client}, {project}
        </Text>
      </Flex>

      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-end"
        gap={2}
        sx={{ width: '180px', flexShrink: 0 }}
      >
        <Text variant="caption" sx={{ fontSize: '16px' }}>
          In Stock: <Text sx={{ fontWeight: 600 }}>{stock}</Text>
        </Text>
        <Flex alignItems="flex-end" flexDirection="column">
          <ShouldRender when={warehouseRoleGlobal === WarehouseRole.Client}>
            <Button
              variant="primary"
              disabled={checkIfActiveOrderHasItem()}
              onClick={handleAddItemToActiveOrder}
              sx={{
                width: '150px',
                fontSize: 'md',
              }}
            >
              Add to Order
            </Button>
          </ShouldRender>

          <ShouldRender
            when={warehouseRoleGlobal === WarehouseRole.Administrator}
          >
            <Flex flexDirection="column" sx={{ gap: 4, width: 'fit-content' }}>
              <Button
                variant="primary"
                disabled={checkIfActiveOrderHasItem()}
                onClick={handleAddItemToActiveOrder}
                sx={{
                  width: '150px',
                  fontSize: 'md',
                }}
              >
                Add to Order
              </Button>

              <Button
                variant="primary"
                disabled={checkIfActiveDeliveryHasItem()}
                onClick={handleAddItemToActiveDelivery}
                sx={{
                  width: '150px',
                  fontSize: 'md',
                  backgroundColor: 'tertiary1.700',
                }}
              >
                Add to Delivery
              </Button>
            </Flex>
          </ShouldRender>
        </Flex>
        <Flex justifyContent="flex-end">
          <Text variant="cardLabelSmall" sx={{ mt: '10px' }}>
            {category}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
