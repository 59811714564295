export const formatDateToUTC = (
  date: Date | string | null | undefined,
  offsetHours = 0,
) => {
  if (!date) return null;

  const inputDate = new Date(date);
  const utcDate = new Date(
    inputDate.getTime() - inputDate.getTimezoneOffset() * 60000,
  );

  const adjustedDate = new Date(
    utcDate.getTime() + -offsetHours * 60 * 60 * 1000,
  );

  return adjustedDate;
};
