import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import { LOCAL_STORAGE_KEYS, useLocalStorage } from 'utils/local-storage';
import { getSelectedWarehouse } from 'utils/warehouse-management/get-selected-warehouse';
import { Box, Container, Flex } from '../../components/layout';
import { Text } from '../../components/typography';
import { Button } from '../../components/form-elements';
import {
  GlobalWarehouseItem,
  WarehouseOverviewTabOptions,
  WarehouseRole,
  WarehouseTab,
} from '../../features/warehouses/types/warehousesTypes';
import { useGlobalState } from '../../components/seo/GlobalState';
import { WarehouseTabs } from '../../features/warehouses/components/WarehouseTabs/WarehouseTabs';
import { ShouldRender } from '../../components/ShouldRender';
import { WarehouseItems } from './components/WarehouseItems/WarehouseItems';
import { WarehouseOrders } from './components/WarehouseOrders/WarehouseOrders';
import { WarehouseDeliveries } from './components/WarehouseDeliveries/WarehouseDeliveries';

export const WarehouseOverview: React.FC = () => {
  const history = ReactRouter.useHistory();

  const selectedWarehouse = getSelectedWarehouse();
  const [selectedDeliveryItems, setSelectedDeliveryItems] = useLocalStorage<
    GlobalWarehouseItem[]
  >(LOCAL_STORAGE_KEYS.SELECTED_DELIVERY_ITEMS, []);

  const handleAddItemClick = () => {
    history.push('/add-item');
  };

  const handleOrderClick = () => {
    if (activeOrderItemsGlobal.length !== 0) {
      history.push('/create-order');
    }
  };

  const handleDeliveryClick = () => {
    history.push('/create-delivery');
  };

  const handleSelectedDeliveryItemsChange = (items: GlobalWarehouseItem[]) => {
    setSelectedDeliveryItems(items);
  };

  const { activeOrderItemsGlobal, warehouseRoleGlobal } = useGlobalState();

  const WarehouseItemsTab: WarehouseTab = {
    name: WarehouseOverviewTabOptions.Items,
    component: (
      <WarehouseItems
        selectedDeliveryItems={selectedDeliveryItems}
        handleSelectedDeliveryItemsChange={handleSelectedDeliveryItemsChange}
      />
    ),
  };

  const WarehouseOrderTab: WarehouseTab = {
    name: WarehouseOverviewTabOptions.Orders,
    component: <WarehouseOrders />,
  };

  const WarehouseDeliveryTab: WarehouseTab = {
    name: WarehouseOverviewTabOptions.Deliveries,
    component: <WarehouseDeliveries />,
  };

  const warehouseTabs: WarehouseTab[] = [
    WarehouseItemsTab,
    WarehouseOrderTab,
    WarehouseDeliveryTab,
  ];

  return (
    <Container variant="fullWidth">
      <Flex justifyContent="space-between" alignItems="center">
        <Text>
          Warehouse{' '}
          <Text sx={{ fontWeight: 'bold' }}>{selectedWarehouse?.name}</Text>
        </Text>

        <Flex sx={{ width: 'auto', gap: 4 }}>
          <ShouldRender
            when={warehouseRoleGlobal === WarehouseRole.Administrator}
          >
            <Button
              disabled={activeOrderItemsGlobal.length === 0}
              variant="primary"
              sx={{
                width: '180px',
                fontSize: 'md',
                backgroundColor:
                  activeOrderItemsGlobal.length === 0
                    ? 'grey.300'
                    : 'tertiary1.300',
              }}
              onClick={handleOrderClick}
            >
              Create Order [{activeOrderItemsGlobal.length}]
            </Button>
          </ShouldRender>

          <ShouldRender
            when={warehouseRoleGlobal === WarehouseRole.Administrator}
          >
            <Button
              variant="primary"
              sx={{
                width: '180px',
                fontSize: 'md',
              }}
              onClick={handleDeliveryClick}
            >
              Create Delivery [
              {selectedDeliveryItems && selectedDeliveryItems.length}]
            </Button>
          </ShouldRender>

          <ShouldRender when={warehouseRoleGlobal === WarehouseRole.Client}>
            <Button
              disabled={activeOrderItemsGlobal.length === 0}
              variant="primary"
              sx={{
                width: '180px',
                fontSize: 'md',
                backgroundColor:
                  activeOrderItemsGlobal.length === 0
                    ? 'grey.300'
                    : 'tertiary1.300',
              }}
              onClick={handleOrderClick}
            >
              Create Order [{activeOrderItemsGlobal.length}]
            </Button>
          </ShouldRender>

          <ShouldRender
            when={warehouseRoleGlobal === WarehouseRole.Administrator}
          >
            <Button
              variant="secondary"
              sx={{ width: '180px', fontSize: 'md' }}
              onClick={handleAddItemClick}
            >
              + Add Item
            </Button>
          </ShouldRender>
        </Flex>
      </Flex>

      <Box sx={{ mt: '30px' }}>
        <WarehouseTabs tabs={warehouseTabs} />
      </Box>
    </Container>
  );
};
