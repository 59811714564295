import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { Button } from 'components/form-elements';
import { DeliveryStatusEnum } from 'trace-backend-sdk';
import { RemoveButton } from '../RemoveButton/RemoveButton';

type WarehouseTabCardProps = {
  name: string;
  client?: string;
  project?: string;
  status?: DeliveryStatusEnum;
  date?: string;
  itemsNumber?: number;
  handleRemoveEvent: () => void;
  handleEventDetailsClick: () => void;
  buttonTitle: React.ReactNode;
};

export const WarehouseTabCard = ({
  name,
  client,
  project,
  status,
  date,
  itemsNumber,
  handleRemoveEvent,
  handleEventDetailsClick,
  buttonTitle,
}: WarehouseTabCardProps) => {
  const newDate = new Date(date || '');
  const formattedDate = date && newDate.toISOString().slice(0, 19).replace('T', ' ').slice(0, -3);

  return (
    <Flex
      sx={{
        backgroundColor: 'white.50',
        p: '20px',
        my: '20px',
        minHeight: '180px',
        borderRadius: 2,
      }}
    >
      <Flex
        sx={{ mr: '20px' }}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Flex flexDirection="column">
          <Text
            sx={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '24px',
              mb: '5px',
            }}
          >
            {name}
          </Text>

          <Text sx={{ fontStyle: 'italic' }}>
            from <Text variant="body2"> {client} </Text>
          </Text>
        </Flex>

        <Text variant="userCardTitle"> {project} </Text>
      </Flex>

      <Flex
        flexDirection="column"
        justifyContent="space-between"
        sx={{ width: '200px', flexShrink: 0 }}
      >
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Text
            variant="caption"
            sx={{ fontSize: '16px', textTransform: 'lowercase' }}
          >
            <Text sx={{ fontWeight: 600 }}>{itemsNumber}</Text>
            {itemsNumber && itemsNumber > 1 ? 'items' : 'item'}
          </Text>

          <RemoveButton onClick={handleRemoveEvent} />
        </Flex>
        {status && (
          <Text sx={{ fontSize: 'md', fontStyle: 'italic' }}>
            Status: {status}
          </Text>
        )}
        <Flex alignItems="center" sx={{ gap: '16px' }}>
          <Button
            disabled={
              status === DeliveryStatusEnum.InProgress
              || status === DeliveryStatusEnum.Delivered
            }
            variant="secondary"
            onClick={handleEventDetailsClick}
            sx={{ height: 'fit-content' }}
          >
            {buttonTitle}
          </Button>
        </Flex>

        <Flex justifyContent="flex-end">
          <Text variant="cardLabelSmall">{formattedDate || ''}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
