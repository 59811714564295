import React from 'react';
import { generateContext } from 'utils/context';
import { GetWarehouseResponse } from 'trace-backend-sdk';
import {
  GlobalWarehouseItem,
  WarehouseRole,
} from '../../features/warehouses/types/warehousesTypes';

export const [useGlobalState, GlobalStateProvider] = generateContext<{
  pageTitle: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  activeWarehouseGlobal: GetWarehouseResponse | undefined;
  setActiveWarehouseGlobal: React.Dispatch<
    React.SetStateAction<GetWarehouseResponse | undefined>
  >;

  activeOrderItemsGlobal: GlobalWarehouseItem[];
  setActiveOrderItemsGlobal: React.Dispatch<
    React.SetStateAction<GlobalWarehouseItem[]>
  >;

  activeDispatchItemsGlobal: GlobalWarehouseItem[];
  setActiveDispatchItemsGlobal: React.Dispatch<
    React.SetStateAction<GlobalWarehouseItem[]>
  >;

  activeDeliveryItemsGlobal: GlobalWarehouseItem[];
  setActiveDeliveryItemsGlobal: React.Dispatch<
    React.SetStateAction<GlobalWarehouseItem[]>
  >;

  warehouseRoleGlobal: WarehouseRole;
  setWarehouseRoleGlobal: React.Dispatch<React.SetStateAction<WarehouseRole>>;
}>();

/**
 * Use context here instead of Little State Machine (LSM), because LSM sends the
 * app into an infinite loop, on every helmet state change.
 * */
export function GlobalState({ children }: { children: React.ReactNode }) {
  const [pageTitle, setPageTitle] = React.useState('');
  const [activeOrderItemsGlobal, setActiveOrderItemsGlobal] = React.useState<
    GlobalWarehouseItem[]
  >([]);
  const [activeDispatchItemsGlobal, setActiveDispatchItemsGlobal] = React.useState<GlobalWarehouseItem[]>([]);
  const [activeDeliveryItemsGlobal, setActiveDeliveryItemsGlobal] = React.useState<GlobalWarehouseItem[]>([]);
  const [activeWarehouseGlobal, setActiveWarehouseGlobal] = React.useState<GetWarehouseResponse>();
  const [warehouseRoleGlobal, setWarehouseRoleGlobal] = React.useState<WarehouseRole>(WarehouseRole.Client);

  const value = React.useMemo(
    () => ({
      pageTitle,
      setPageTitle,
      activeOrderItemsGlobal,
      setActiveOrderItemsGlobal,
      activeWarehouseGlobal,
      setActiveWarehouseGlobal,
      warehouseRoleGlobal,
      setWarehouseRoleGlobal,
      activeDispatchItemsGlobal,
      setActiveDispatchItemsGlobal,
      activeDeliveryItemsGlobal,
      setActiveDeliveryItemsGlobal,
    }),
    [
      pageTitle,
      setPageTitle,
      activeOrderItemsGlobal,
      setActiveOrderItemsGlobal,
      activeWarehouseGlobal,
      setActiveWarehouseGlobal,
      warehouseRoleGlobal,
      setWarehouseRoleGlobal,
      activeDispatchItemsGlobal,
      setActiveDispatchItemsGlobal,
      activeDeliveryItemsGlobal,
      setActiveDeliveryItemsGlobal,
    ],
  );

  return <GlobalStateProvider value={value}>{children}</GlobalStateProvider>;
}
