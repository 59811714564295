import * as React from 'react';
import { Button, Forms } from 'components/form-elements';
import * as Rhf from 'react-hook-form';
import * as ReactRouter from 'react-router-dom';
import { DatePicker } from 'features/warehouses/components/DatePicker/DatePicker';
import * as UUID from 'uuid';
import { useParams } from 'react-router-dom';
import { Box, Container, Grid } from '../../components/layout';
import { useGlobalState } from '../../components/seo/GlobalState';
import { Text } from '../../components/typography';
import { Textarea } from '../../components/Textarea/Textarea';
import { ShouldRender } from '../../components/ShouldRender';
import {
  WarehouseOrder,
  WarehouseRole,
} from '../../features/warehouses/types/warehousesTypes';
import {
  projectsMock,
  workersMock,
} from '../../features/warehouses/mockData/warehousesMockData';
import { CreateOrderItemCard } from './components/CreateOrderItemCard/CreateOrderItemCard';

export const CreateOrder = () => {
  const formMethods = Rhf.useForm({
    defaultValues: {
      name: '',
      address: '',
      email: '',
      phoneNumber: '',
      project: projectsMock[0],
      assignedTo: workersMock[0],
    },
  });

  const uuid = UUID.v4();

  const history = ReactRouter.useHistory();

  const params = useParams<{ id: string }>();
  const orderId = params.id;

  const { activeOrderItemsGlobal, warehouseRoleGlobal } = useGlobalState();

  const [orderNotesValue, setOrderNotesValue] = React.useState<string>('');

  const handleOrderNotesChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setOrderNotesValue(e.target.value);
  };

  const [arrivalDate, setArrivalDate] = React.useState<Date | null>(new Date());

  const generateOrderButtonText = () => {
    if (warehouseRoleGlobal === WarehouseRole.Administrator) {
      return <Text intlId="order.approveOrder" variant="button.text" />;
    }

    if (warehouseRoleGlobal === WarehouseRole.Client && orderId) {
      return <Text intlId="order.editOrder" variant="button.text" />;
    }

    return <Text intlId="order.createOrder" variant="button.text" />;
  };

  return (
    <Container variant="container">
      {activeOrderItemsGlobal.map((item) => (
        <CreateOrderItemCard item={item} key={item.id} />
      ))}

      <Forms.Provider
        // TODO: replace with a BE call
        onValid={async (data) => {
          const order: WarehouseOrder = {
            id: uuid, //Order ID will be created on the BE, not here. This is required for Editing and Approving later on
            name: data.name,
            address: data.address,
            email: data.email,
            phoneNumber: data.phoneNumber,
            notes: orderNotesValue,
            items: activeOrderItemsGlobal,
            date: arrivalDate,
            client: 'Active Client', // TODO Replace with the currently logged in Client
            project: data.project,
            assignedTo: data.assignedTo,
            confirmed: false,
            createdOn: new Date(),
          };

          /* eslint-disable-next-line no-console */
          console.log('order: ', order);
        }}
        name="createOrder"
        {...formMethods}
      >
        <Grid columns={2}>
          <Forms.FieldEditText
            labelIntlId="createOrder.form.name"
            name="name"
            required
          />

          <Forms.FieldEditText
            labelIntlId="createWarehouse.form.input.address.label"
            name="address"
            required
          />

          <Forms.FieldEditText
            onMapChange={(value) => value.trim()}
            labelIntlId="login.form.input.email.label"
            name="email"
          />

          <Forms.FieldEditText
            onMapChange={(value) => value.trim()}
            labelIntlId="createAccount.form.input.phoneNumber.label"
            name="phoneNumber"
          />

          <Forms.SelectField
            labelIntlId="delivery.addDelivery.project"
            name="project"
          >
            {projectsMock.map((project) => (
              <option value={project} key={`project-select.option__${project}`}>
                {project}
              </option>
            ))}
          </Forms.SelectField>

          <ShouldRender
            when={warehouseRoleGlobal === WarehouseRole.Administrator}
          >
            <Forms.SelectField
              labelIntlId="delivery.addDelivery.worker"
              name="assignedTo"
            >
              {workersMock.map((worker) => (
                <option
                  value={worker}
                  key={`assigned-to-select.option__${worker}`}
                >
                  {worker}
                </option>
              ))}
            </Forms.SelectField>
          </ShouldRender>
        </Grid>

        <Textarea
          isLabelAlwaysLifted={false}
          label="Notes"
          isError={false}
          name="notes"
          value={orderNotesValue}
          onChange={handleOrderNotesChange}
        />

        <Grid columns={2}>
          <label htmlFor="birthday">Estimated arrival date:</label>
          <DatePicker startDate={arrivalDate} setStartDate={setArrivalDate} />
          <Box />
        </Grid>

        <Grid columns={2}>
          <Button variant="preview" onClick={() => history.goBack()}>
            <Text intlId="generic.button.goBack" />
          </Button>

          <Forms.SubmitButton sx={{ height: 'auto' }}>
            {/*<Text intlId="createOrder.button.createOrder" variant="button.text" />*/}
            {generateOrderButtonText()}
          </Forms.SubmitButton>
        </Grid>
      </Forms.Provider>
    </Container>
  );
};
