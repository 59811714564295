import * as React from 'react';
import { WarehouseService } from 'services/warehouse';
import { Delivery } from 'trace-backend-sdk';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { Text } from 'components/typography';
import { Container, Grid } from '../../../../components/layout';
import { WarehouseDeliveriesCard } from './components/WarehouseDeliveriesCard/WarehouseDeliveriesCard';

export const WarehouseDeliveries = () => {
  const { allDeliveries, isLoading } = WarehouseService.useGetAllDeliveries();

  if (isLoading) {
    return <InlineLoadingIndicator />;
  }

  return (
    <Container
      variant="container"
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {allDeliveries && allDeliveries?.length > 0 ? (
        <Grid columns={2}>
          {allDeliveries?.map((delivery: Delivery) => {
            return (
              <WarehouseDeliveriesCard
                delivery={delivery}
                key={`${delivery.id}`}
              />
            );
          })}
        </Grid>
      ) : (
        <Text
          intlId="tab.deliveries.noDeliveries.message"
          sx={{ textAlign: 'center', fontStyle: 'italic', width: 'full' }}
        />
      )}
    </Container>
  );
};
