import * as React from 'react';
import { useModal } from 'store/modals';
import { breakpoints } from 'utils/breakpoints';
import { ShouldRender } from 'components/ShouldRender';
import { useGlobalState } from 'components/seo/GlobalState';
import { WarehouseService } from 'services/warehouse';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import { Container, Flex, Grid } from '../../components/layout';
import { Text } from '../../components/typography';
import { Button } from '../../components/form-elements';
import { WarehouseRole } from '../../features/warehouses/types/warehousesTypes';
import { AddWarehouse } from './components/AddWarehouse/AddWarehouse';
import { WarehouseCard } from './components/WarehouseCard/WarehouseCard';

export const Warehouses: React.FC = () => {
  const { openModal, closeModal } = useModal();
  const { warehouseRoleGlobal } = useGlobalState();
  const { warehouses, isLoading } = WarehouseService.useGetAllWarehouses();

  if (isLoading) {
    return <PageLoadingIndicator />;
  }

  return (
    <Container
      variant="fullWidth"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text>All Warehouses</Text>
        <ShouldRender
          when={warehouseRoleGlobal === WarehouseRole.Administrator}
        >
          <Button
            variant="secondary"
            sx={{
              width: '300px',
            }}
            onClick={() =>
              openModal({
                modalType: 'wideComponentModal',
                modalProps: {
                  children: <AddWarehouse closeModal={closeModal} />,
                },
              })}
          >
            + Add new Warehouse
          </Button>
        </ShouldRender>
      </Flex>
      {warehouses?.length !== 0 ? (
        <Grid
          columns={breakpoints({ _: 1, sm: 2 })}
          sx={{
            maxWidth: '1440px',
            gap: 8,
            alignItems: 'center',
          }}
        >
          {warehouses?.map((warehouse) => (
            <WarehouseCard warehouse={warehouse} key={warehouse.id} />
          ))}
        </Grid>
      ) : (
        <>
          <ShouldRender
            when={warehouseRoleGlobal === WarehouseRole.Administrator}
          >
            <Text
              sx={{
                mt: '100px',
                maxWidth: '500px',
                textAlign: 'center',
                fontStyle: 'italic',
              }}
              intlId="warehouses.admin.noWarehousesFound.message"
            />
          </ShouldRender>
          <ShouldRender when={warehouseRoleGlobal === WarehouseRole.Client}>
            <Text
              sx={{
                mt: '100px',
                maxWidth: '500px',
                textAlign: 'center',
                fontStyle: 'italic',
              }}
              intlId="warehouses.client.noWarehousesFound.message"
            />
          </ShouldRender>
        </>
      )}
    </Container>
  );
};
