import * as React from 'react';
import { Box, Flex } from '../../../../components/layout';
import {
  WarehouseOverviewTabOptions,
  WarehouseRole,
  WarehouseTab,
} from '../../types/warehousesTypes';
import { Button } from '../../../../components/workspace-layout/components';
import { useGlobalState } from '../../../../components/seo/GlobalState';

type WarehouseTabsProps = {
  tabs: WarehouseTab[];
};

export const WarehouseTabs = ({ tabs }: WarehouseTabsProps) => {
  const [activeTab, setActiveTab] = React.useState(
    WarehouseOverviewTabOptions.Items,
  );

  const { warehouseRoleGlobal } = useGlobalState();

  const handleSetActiveTab = (tabName: WarehouseOverviewTabOptions): void => {
    setActiveTab(tabName);
  };

  const generateTabComponent = () => {
    const tab = tabs.find((aTab) => aTab.name === activeTab);
    return tab?.component;
  };

  return (
    <Box>
      <Flex>
        {tabs.map((tab: WarehouseTab) => {
          if (
            warehouseRoleGlobal === WarehouseRole.Client
            && tab.name === WarehouseOverviewTabOptions.Deliveries
          ) {
            return null;
          }

          return (
            <Button
              sx={
                tab.name === activeTab
                  ? {
                    backgroundColor: 'bg.50',
                    borderRadius: '0px',
                    textTransform: 'capitalize',
                    color: 'primary.900',
                    fontSize: '24px',
                    textDecoration: 'underline',
                    textDecorationThickness: '3px',
                    textDecorationColor: '#3192F1',
                    textUnderlineOffset: '8px',
                  }
                  : {
                    backgroundColor: 'bg.50',
                    borderRadius: '0px',
                    textTransform: 'capitalize',
                    color: 'primary.900',
                    fontSize: '24px',
                  }
              }
              onClick={() => handleSetActiveTab(tab.name)}
              key={tab.name}
            >
              {tab.name}
            </Button>
          );
        })}
      </Flex>

      <Box>{generateTabComponent()}</Box>
    </Box>
  );
};
