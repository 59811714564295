import { DeliveryItems } from 'trace-backend-sdk';

export const areItemsEqual = (
  items1: DeliveryItems[],
  items2: DeliveryItems[],
) => {
  if (items1.length !== items2.length) return false;

  const sortedItems1 = [...items1].sort((a, b) => a.id.localeCompare(b.id));
  const sortedItems2 = [...items2].sort((a, b) => a.id.localeCompare(b.id));

  return sortedItems1.every((item, index) => {
    const compareItem = sortedItems2[index];
    return (
      item.id === compareItem.id
      && item.plannedQuantity === compareItem.plannedQuantity
      && item.unit === compareItem.unit
    );
  });
};
