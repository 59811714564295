import * as React from 'react';
import { CreateEventItemCard } from 'features/warehouses/components/CreateEventItemCard/CreateEventItemCard';
import * as ReactRouter from 'react-router-dom';
import { GlobalWarehouseItem } from '../../../../features/warehouses/types/warehousesTypes';
import { useGlobalState } from '../../../../components/seo/GlobalState';

type CreateOrderItemCardProps = {
  item: GlobalWarehouseItem;
};

export const CreateOrderItemCard = ({ item }: CreateOrderItemCardProps) => {
  const { id } = item;

  const history = ReactRouter.useHistory();

  const { activeOrderItemsGlobal, setActiveOrderItemsGlobal } = useGlobalState();

  const itemIndex = activeOrderItemsGlobal.findIndex(
    (currentItem) => currentItem.id === id,
  );

  const [itemQuantity, setItemQuantity] = React.useState<number>(
    activeOrderItemsGlobal[itemIndex].orderQuantity || 0,
  );

  const formatToValidInput = (value: number) => {
    if (Number.isNaN(value)) return 1;
    if (value <= 1) return 1;
    return value;
  };

  const updateActiveOrderGlobalItemQuantity = (quantity: number) => {
    const updatedActiveOrderGlobal = [...activeOrderItemsGlobal];
    if (
      updatedActiveOrderGlobal[itemIndex]
      && updatedActiveOrderGlobal[itemIndex].orderQuantity !== undefined
    ) {
      updatedActiveOrderGlobal[itemIndex].orderQuantity = formatToValidInput(quantity);
    }
    setActiveOrderItemsGlobal(updatedActiveOrderGlobal);
  };

  const handleItemQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = formatToValidInput(e.target.valueAsNumber);
    setItemQuantity(quantity);
    updateActiveOrderGlobalItemQuantity(quantity);
  };

  const handleRemoveItem = () => {
    const text = activeOrderItemsGlobal.length === 1
      ? "You can't create order without items. Are you sure you want to remove this item from the order?"
      : 'Are you sure you want to remove this item from the order?';
    // eslint-disable-next-line no-alert
    if (window.confirm(text)) {
      const updatedActiveOrderGlobal = activeOrderItemsGlobal.filter(
        (anItem) => anItem.id !== id,
      );
      setActiveOrderItemsGlobal(updatedActiveOrderGlobal);
      if (updatedActiveOrderGlobal.length === 0) {
        history.goBack();
      }
    }
  };

  const handlePlusButtonClick = () => {
    const increasedByOne = formatToValidInput(itemQuantity + 1);
    setItemQuantity(increasedByOne);
    updateActiveOrderGlobalItemQuantity(increasedByOne);
  };

  const handleMinusButtonClick = () => {
    const decreasedByOne = formatToValidInput(itemQuantity - 1);
    setItemQuantity(decreasedByOne);
    updateActiveOrderGlobalItemQuantity(decreasedByOne);
  };

  return (
    <CreateEventItemCard
      name={item.name}
      category={item.category}
      project={item.project}
      stock={item.stock}
      itemQuantity={itemQuantity}
      handleMinusButtonClick={handleMinusButtonClick}
      handleItemQuantityChange={handleItemQuantityChange}
      handlePlusButtonClick={handlePlusButtonClick}
      handleRemoveItem={handleRemoveItem}
    />
  );
};
