import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import { WarehouseTabCard } from 'features/warehouses/components/WarehouseTabCard/WarehouseTabCard';
import { WarehouseOrder } from 'features/warehouses/types/warehousesTypes';
import { Text } from '../../../../../../components/typography';

type WarehouseOrdersCardProps = {
  order: WarehouseOrder;
};

export const WarehouseOrdersCard = ({ order }: WarehouseOrdersCardProps) => {
  const history = ReactRouter.useHistory();

  const { id } = order;

  const handleOrderDetailsClick = () => {
    if (id) {
      history.push(`/order/${id}`);
    }
  };

  const handleRemoveOrder = () => {
    const text = 'Are you sure you want to remove this Order?';

    // eslint-disable-next-line no-alert
    if (window.confirm(text)) {
      // eslint-disable-next-line no-console
      console.log('BE call to remove order: ', id);
    }
  };

  return (
    <WarehouseTabCard
      name={order.name ? order.name : ''}
      project={order.project}
      client={order.client}
      itemsNumber={order.items?.length}
      handleRemoveEvent={handleRemoveOrder}
      handleEventDetailsClick={handleOrderDetailsClick}
      buttonTitle={
        <Text intlId="tab.orders.card.button" variant="button.text" />
      }
    />
  );
};
