import { Item } from 'trace-backend-sdk';

export type Warehouse = {
  id: string;
  name: string;
  location: string;
  address: string;
  image?: string;
};
export interface GlobalWarehouseItem extends Item {
  orderQuantity?: number;
  image: string;
  project?: string;
  stock?: number;
  position?: string;
  client?: string;
  zone?: string;
  floor?: number;
}

export type WarehouseItem = {
  id: string;
  barcodeId: string;
  name: string;
  position: string;
  stock: number;
  description: string;
  category: string;
  project: string;
  image?: string;
  orderQuantity?: number;
  producingCompany?: string;
  color?: string;
  weight?: string;
  size?: string;
};

export enum WarehouseRole {
  Administrator = 'Administrator',
  Client = 'Client',
  // Worker = 'Worker',
}

export enum WarehouseOverviewTabOptions {
  Items = 'Items',
  Orders = 'Orders',
  Deliveries = 'Deliveries',
}

export type WarehouseTab = {
  name: WarehouseOverviewTabOptions;
  component: JSX.Element;
};

export type WarehouseOrder = {
  id?: string;
  name: string;
  address?: string;
  email?: string;
  phoneNumber?: string;
  notes?: string;
  items?: GlobalWarehouseItem[];
  date: Date | null;
  client?: string;
  project: string;
  assignedTo: string | undefined;
  confirmed?: boolean;
  createdOn: Date;
  status?: string;
};

export type WarehouseDispatch = {
  items?: GlobalWarehouseItem[];
  id?: string;
  name: string;
  address?: string;
  email?: string;
  phoneNumber?: string;
  client?: string;
  project: string;
  assignedTo: string | undefined;
  notes?: string;
  date: Date;
  createdOn: Date;
};

export type WarehouseDelivery = {
  items?: GlobalWarehouseItem[];
  id?: string;
  name: string;
  address?: string;
  email?: string;
  phoneNumber?: string;
  client?: string;
  project: string;
  assignedTo: string | undefined;
  notes?: string;
  date: Date;
  status: string;
  createdOn: Date;
};
