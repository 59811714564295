import * as React from 'react';
import { Box } from '../../../../components/layout';
import { GlobalWarehouseItem } from '../../../../features/warehouses/types/warehousesTypes';
import { warehouseItemsMockData } from '../../../../features/warehouses/mockData/warehousesMockData';
import { WarehouseItemCard } from './components/WarehouseItemCard/WarehouseItemCard';

type WarehouseItemsProps = {
  selectedDeliveryItems: GlobalWarehouseItem[] | undefined;
  handleSelectedDeliveryItemsChange: (item: GlobalWarehouseItem[]) => void;
};

export const WarehouseItems = ({
  selectedDeliveryItems,
  handleSelectedDeliveryItemsChange,
}: WarehouseItemsProps) => {
  return (
    <Box>
      {warehouseItemsMockData.map((item: GlobalWarehouseItem) => (
        <WarehouseItemCard
          item={item}
          key={item.id}
          selectedDeliveryItems={selectedDeliveryItems}
          handleSelectedDeliveryItemsChange={handleSelectedDeliveryItemsChange}
        />
      ))}
    </Box>
  );
};
