import * as React from 'react';
import { Container, Grid } from '../../../../components/layout';
import { WarehouseOrder } from '../../../../features/warehouses/types/warehousesTypes';
import { warehouseItemsMockData } from '../../../../features/warehouses/mockData/warehousesMockData';
import { WarehouseOrdersCard } from './components/WarehouseOrdersCard/WarehouseOrdersCard';

const warehouseOrderMockData: WarehouseOrder[] = [
  {
    id: '11111',
    name: 'Order 1',
    address: 'Address 1',
    email: 'email@first.com',
    phoneNumber: '111-111-111',
    notes: 'a long note with may words in it',
    items: warehouseItemsMockData,
    date: new Date(),
    client: 'Client 1',
    project: 'Project 1',
    assignedTo: 'John',
    confirmed: false,
    createdOn: new Date(),
  },
  {
    id: '22222',
    name: 'Order 2',
    address: 'Address 2',
    email: 'email@second.com',
    phoneNumber: '222-222-222',
    notes: 'a long note with may words in it',
    items: warehouseItemsMockData,
    date: new Date(),
    client: 'Client 2',
    project: 'Project 2',
    assignedTo: 'John',
    confirmed: false,
    createdOn: new Date(),
  },
  {
    id: '33333',
    name: 'Order 3',
    address: 'Address 3',
    email: 'email@third.com',
    phoneNumber: '333-333-333',
    notes: 'a long note with may words in it',
    items: warehouseItemsMockData,
    date: new Date(),
    client: 'Client 3',
    project: 'Project 3',
    assignedTo: 'John',
    confirmed: false,
    createdOn: new Date(),
  },
  {
    id: '44444',
    name: 'Order 4',
    address: 'Address 4',
    email: 'email@fourth.com',
    phoneNumber: '444-444-444',
    notes: 'a long note with may words in it',
    items: warehouseItemsMockData,
    date: new Date(),
    client: 'Client 4',
    project: 'Project 4',
    assignedTo: 'John',
    confirmed: false,
    createdOn: new Date(),
  },
  {
    id: '55555',
    name: 'Order 5',
    address: 'Address 5',
    email: 'email@fifth.com',
    phoneNumber: '555-555-555',
    notes: 'a long note with may words in it',
    items: warehouseItemsMockData,
    date: new Date(),
    client: 'Client 5',
    project: 'Project 5',
    assignedTo: 'John',
    confirmed: false,
    createdOn: new Date(),
  },
  {
    id: '66666',
    name: 'Order 6',
    address: 'Address 6',
    email: 'email@sixth.com',
    phoneNumber: '666-666-666',
    notes: 'a long note with may words in it',
    items: warehouseItemsMockData,
    date: new Date(),
    client: 'Client 6',
    project: 'Project 6',
    assignedTo: 'John',
    confirmed: false,
    createdOn: new Date(),
  },
  {
    id: '77777',
    name: 'Order 7',
    address: 'Address 7',
    email: 'email@seventh.com',
    phoneNumber: '777-777-777',
    notes: 'a long note with may words in it',
    items: warehouseItemsMockData,
    date: new Date(),
    client: 'Client 7',
    project: 'Project 7',
    assignedTo: 'John',
    confirmed: false,
    createdOn: new Date(),
  },
];

export const WarehouseOrders = () => {
  return (
    <Container variant="container">
      <Grid columns={2}>
        {warehouseOrderMockData.map((order: WarehouseOrder) => {
          return <WarehouseOrdersCard order={order} key={`${order.id}`} />;
        })}
      </Grid>
    </Container>
  );
};
