import * as React from 'react';
import { CreateEventItemCard } from 'features/warehouses/components/CreateEventItemCard/CreateEventItemCard';
import { DeliveryItems } from 'trace-backend-sdk';

type CreateDeliveryItemCardProps = {
  item: DeliveryItems;
  deliveryProject?: string;
  deliveryItems: DeliveryItems[] | undefined;
  handleSelectedDeliveryItemsChange: (items: DeliveryItems[]) => void;
  updateRemovedDeliveryItemsId: (id: string) => void;
};

export const CreateDeliveryItemCard = ({
  item,
  deliveryItems,
  deliveryProject,
  handleSelectedDeliveryItemsChange,
  updateRemovedDeliveryItemsId,
}: CreateDeliveryItemCardProps) => {
  const { id } = item;
  const itemIndex = deliveryItems?.findIndex((currentItem) => currentItem.id === id) ?? -1;

  const [itemQuantity, setItemQuantity] = React.useState<number>(
    (deliveryItems && deliveryItems[itemIndex]?.plannedQuantity) || 0,
  );

  const formatToValidInput = (value: number) => {
    if (Number.isNaN(value)) return 1;
    if (value <= 1) return 1;
    return value;
  };

  const updateActiveDeliveryGlobalItemQuantity = (quantity: number) => {
    if (itemIndex >= 0 && deliveryItems) {
      const updatedActiveDeliveryGlobal = [...deliveryItems];
      if (updatedActiveDeliveryGlobal[itemIndex]) {
        updatedActiveDeliveryGlobal[itemIndex].plannedQuantity = formatToValidInput(quantity);
      }
      handleSelectedDeliveryItemsChange(updatedActiveDeliveryGlobal);
    }
  };

  const handleItemQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = formatToValidInput(e.target.valueAsNumber);
    setItemQuantity(quantity);
    updateActiveDeliveryGlobalItemQuantity(quantity);
  };

  const handleRemoveItem = () => {
    const text = 'Are you sure you want to remove this item from the delivery?';
    // eslint-disable-next-line no-alert
    if (window.confirm(text) && deliveryItems) {
      const updatedActiveDeliveryGlobal = deliveryItems.filter(
        (item) => item.id !== id,
      );
      handleSelectedDeliveryItemsChange(updatedActiveDeliveryGlobal);
      updateRemovedDeliveryItemsId(item.itemId);
    }
  };

  const handlePlusButtonClick = () => {
    const increasedByOne = formatToValidInput(itemQuantity + 1);
    setItemQuantity(increasedByOne);
    updateActiveDeliveryGlobalItemQuantity(increasedByOne);
  };

  const handleMinusButtonClick = () => {
    const decreasedByOne = formatToValidInput(itemQuantity - 1);
    setItemQuantity(decreasedByOne);
    updateActiveDeliveryGlobalItemQuantity(decreasedByOne);
  };

  return (
    <CreateEventItemCard
      name={item.name}
      project={deliveryProject}
      stock={item.plannedQuantity}
      imageUrl={item.image}
      category={item.category}
      itemQuantity={itemQuantity}
      handleMinusButtonClick={handleMinusButtonClick}
      handleItemQuantityChange={handleItemQuantityChange}
      handlePlusButtonClick={handlePlusButtonClick}
      handleRemoveItem={handleRemoveItem}
    />
  );
};
