import { GlobalWarehouseItem } from '../types/warehousesTypes';
import BrickImage from '../assets/Bricks.jpg';
import LumberImage from '../assets/Lumber.jpg';
import PipesImage from '../assets/Pipes.jpg';

export const projectsMock = [
  'Project 1',
  'Project 2',
  'Project 3',
  'Project 4',
  'Project 5',
];

export const workersMock = ['John', 'Marry', 'Bob', 'Sarah', 'Tom'];

export const warehouseItemsMockData: GlobalWarehouseItem[] = [
  {
    id: 'itemId1',
    code: '#11',
    name: 'Item name 1',
    position: 'Slot 1 / Shelf 1',
    stock: 100,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing e.',
    category: 'Category 1',
    project: 'Project 1',
    client: 'Client 1',
    company: 'Company 1',
    color: 'Color 1',
    weight: 'Weight 1',
    size: 'Size 1',
    zone: 'Zone 1',
    floor: 1,
    image: BrickImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
  {
    id: 'itemId2',
    code: '#22',
    name: 'Item name 2',
    position: 'Slot 2 / Shelf 2',
    stock: 200,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing e.',
    category: 'Category 2',
    project: 'Project 2',
    client: 'Client 2',
    company: 'Company 2',
    color: 'Color 2',
    weight: 'Weight 2',
    size: 'Size 2',
    zone: 'Zone 2',
    floor: 2,
    image: LumberImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
  {
    id: 'itemId3',
    code: '#33',
    name: 'Item name 3',
    position: 'Slot 3/ Shelf 3',
    stock: 300,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing e.',
    category: 'Category 3',
    project: 'Project 3',
    client: 'Client 3',
    company: 'Company 3',
    color: 'Color 3',
    weight: 'Weight 3',
    size: 'Size 3',
    zone: 'Zone 3',
    floor: 3,
    image: PipesImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
  {
    id: 'itemId4',
    code: '#44',
    name: 'Item name 4',
    position: 'Slot 3/ Shelf 3',
    stock: 300,
    description: 'A description 3',
    category: 'Category 3',
    project: 'Project 3',
    client: 'Client 4',
    company: 'Company 4',
    color: 'Color 4',
    weight: 'Weight 4',
    size: 'Size 4',
    zone: 'Zone 4',
    floor: 4,
    image: PipesImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
  {
    id: 'itemId5',
    code: '#55',
    name: 'Item name 5',
    position: 'Slot 3/ Shelf 3',
    stock: 300,
    description: 'A description 3',
    category: 'Category 3',
    project: 'Project 3',
    client: 'Client 5',
    company: 'Company 5',
    color: 'Color 5',
    weight: 'Weight 5',
    size: 'Size 5',
    zone: 'Zone 5',
    floor: 5,
    image: PipesImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
  {
    id: 'itemId6',
    code: '#66',
    name: 'Item name 6',
    position: 'Slot 3/ Shelf 3',
    stock: 300,
    description: 'A description 3',
    category: 'Category 3',
    project: 'Project 3',
    client: 'Client 6',
    company: 'Company 6',
    color: 'Color 6',
    weight: 'Weight 6',
    size: 'Size 6',
    zone: 'Zone 6',
    floor: 6,
    image: PipesImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
  {
    id: 'itemId7',
    code: '#77',
    name: 'Item name 7',
    position: 'Slot 3/ Shelf 3',
    stock: 300,
    description: 'A description 3',
    category: 'Category 3',
    project: 'Project 3',
    client: 'Client 7',
    company: 'Company 7',
    color: 'Color 7',
    weight: 'Weight 7',
    size: 'Size 7',
    zone: 'Zone 7',
    floor: 7,
    image: PipesImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
  {
    id: 'itemId8',
    code: '#88',
    name: 'Item name 8',
    position: 'Slot 3/ Shelf 3',
    stock: 300,
    description: 'A description 3',
    category: 'Category 3',
    project: 'Project 3',
    client: 'Client 8',
    company: 'Company 8',
    color: 'Color 8',
    weight: 'Weight 8',
    size: 'Size 8',
    zone: 'Zone 8',
    floor: 8,
    image: PipesImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
  {
    id: 'itemId9',
    code: '#99',
    name: 'Item name 9',
    position: 'Slot 3/ Shelf 3',
    stock: 300,
    description: 'A description 3',
    category: 'Category 3',
    project: 'Project 3',
    client: 'Client 9',
    company: 'Company 9',
    color: 'Color 9',
    weight: 'Weight 9',
    size: 'Size 9',
    zone: 'Zone 9',
    floor: 9,
    image: PipesImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
  {
    id: 'itemId10',
    code: '#1010',
    name: 'Item name 10',
    position: 'Slot 3/ Shelf 3',
    stock: 300,
    description: 'A description 3',
    category: 'Category 3',
    project: 'Project 3',
    client: 'Client 10',
    company: 'Company 10',
    color: 'Color 10',
    weight: 'Weight 10',
    size: 'Size 10',
    zone: 'Zone 10',
    floor: 10,
    image: PipesImage,
    orderQuantity: 1,
    dateCreated: '22-08-2024',
    dateUpdated: '22-08-2024',
  },
];
